body {
  font-family: Inter, sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-synthesis: weight style; */
  background-color: #d7eff4;
}

::-webkit-scrollbar {
  width: 6px;
}
/* Track */
::-webkit-scrollbar-track {
  border-radius: 8px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  margin: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #000000;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.intercom-lightweight-app {
  z-index: 1010 !important;
}

.intercom-with-namespace-52k34s {
 z-index: 1010 !important;
}