.mainLogo {
  height: 50px;
  padding-top: 10px;
}
.products_background {
  position: absolute;
  z-index: -1;
  background-color: transparent;
}
.products_body {
  text-align: center;
  height: 100%;
  place-content: center;
  align-items: center;
  color: #000000;
  margin: auto;
  overflow-y: auto;
}

.products_header {
  text-align: center;
  z-index: -2;
  max-width: 900px;
  margin: 10px auto;
  border-radius: 20px;
}
.cards_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-height: 600px;
  max-width: 1100px;
  column-gap: 30px;
  margin: 20px auto;
  background-color: white;
  border-radius: 20px;
  padding: 2%;
  overflow-y: auto;
}
.workers_cards {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.card {
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.2);
  transition: 1s;
  opacity: 0.6;
  border-radius: 15px;
  margin: auto;
  max-width: 300px;
}
.card:hover {
  opacity: 1;
  box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.card_image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 250px;
  display: flex;
  border-radius: 15px;
  transition: 1s;
  text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff,
    1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
  color: #000000;
}

.card_detail {
  opacity: 1;
}
.card_detail:hover {
  cursor: auto;
}

.nav {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  background-color: transparent;
  display: flex;
  justify-content: center;
}

li a {
  display: block;
  color: #000000;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  border-radius: 15px;
  margin: 0 10px;
  transition: 0.5s;
}

li a:hover:not(.active) {
  background-color: #000000;
  color: white;
}

.link {
  color: inherit;
  text-decoration: none;
}

.link_2 {
  color: #35aac5;
}
.active {
  color: white;
  background-color: #000000;
}
.interestedFooter {
  color: white;
  font-weight: 100;
  float: right;
  cursor: pointer;
  margin: 0;
  padding: 8px;
  background-color: #000000;
  border-radius: 20px;
  transition: 1s;
}
.interestedFooter:hover {
  background-color: white;
  color: #f44c7f;
}
.notFound {
  height: 300px;
}
.notFoundButton {
  margin-top: 50px;
  color: white;
  background-color: #000000;
  padding: 20px;
  border-radius: 20px;
}
.notFoundButton:hover {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .cards_container {
    grid-template-columns: 1fr;
    margin: 0 5%;
    padding: 5%;
  }
  .card {
    margin: 5px auto;
    opacity: 1;
  }
  .container {
    padding: 0;
  }
  .card_detail {
    display: none;
  }
  .mainLogo {
    height: 40px;
    width: 40px;
  }
  .notFoundButton {
    margin-top: 0px;
  }
}

@keyframes slide-up {
  from {
    margin-top: 100%;
    /* height: 300%; */
  }
  to {
    margin-top: 0%;
    /* height: 100%; */
  }
}
